html,
body {
    overflow-x: hidden;
    overscroll-behavior: contain;
    background-color: #1a1a1a;
    font-family: 'Roboto', sans-serif;
    font-size: 16px;
    color: white;
    padding: 5px;
}
