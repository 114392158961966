html, body {
  overscroll-behavior: contain;
  color: #fff;
  background-color: #1a1a1a;
  padding: 5px;
  font-family: Roboto, sans-serif;
  font-size: 16px;
  overflow-x: hidden;
}
/*# sourceMappingURL=index.d0d03a82.css.map */
